<template>
  <van-popup :value="value" position="top" :style="{ height: '100%', position: 'absolute' }" round @closed="close" @input="close">
    <div class="extend zm-flex pd20 is-column policy">
        <div class="fs28 tc pb10">隐私政策</div>
      <div class="flex-1 content" v-html="agreeContent">
      </div>
      <div class="df-center pt10">
        <van-button type="default" class="flex-1" @click="cancel">拒绝</van-button>
        <van-button type="info" class="flex-1" @click="confirm">同意</van-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
import agreeContent from '@/model/agreeContent';
export default {
  name: 'policy',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      agreeContent
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    close() {
        console.log('close');
      this.$emit('input', false);
    },
    confirm() {
      this.close();
    },
    cancel() {
      this.$emit('cancel');
      this.close();
    }
  }
};
</script>
<style lang="scss">
    .policy{
        .content{
            overflow-x: hidden;
            overflow-y: scroll;
        }
        p{
            text-indent: 2em;
        }
    }
</style>
